<template>
  <div class="bigbox">
    <!-- 智慧法务top -->
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span class="active2">产品解决方案</span>
                <ul class="menu">
                  <span class="active" @click="go('/cpjjfa/zhfw')">智慧法务</span>
                  <li @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
    </div>

      <!-- <div class="zhfwindc">智慧法务管理平台，提供从合同拟制、审核签署、履行及变更、结束及归档全流程管理，结合系统完善的功能及流程配置，全面防范企业法律风险；利用灵活的报表工具、数据分析工具为企业经营决策提供数据支撑；实现法务管理从“被动灭火”，到“主动灭火”，再到“法务驱动业务”的转变。</div> -->
    </div>
    <!-- 行业痛点 -->
    <div class="hytd">
      <div class="hytdcontent">
        <div class="hytdcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">行业痛点</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="hytd_bottom">
          <div class="hytd_bottom1">
            <div class="hytd_bottom1left">合同发起审批信息、合同业务履 行信息、合同财务履行信息及合 同纠纷信息分散在各个部门或各 个系统中，并且并无关联。</div>
            <div class="hytd_bottom1right"><img src="../.././assets/hytd1.png" alt=""></div>
          </div>
          <div class="hytd_bottom2">
            <div class="hytd_bottom1right"><img src="../.././assets/hytd2.png" alt=""></div>
            <div class="hytd_bottom1left">统计分析维度多，且各个部 门关注的内容有差异，缺乏 有效的平台支持大量数据的综合性统计分析。</div>
          </div>
          <div class="hytd_bottom3">
            <div class="hytd_bottom1left">合同相对方信息不共享，导致同 一个相对方可能已经与集团内某 公司发生纠纷，但另一家公司仍 在与该相对方谈合作。</div>
            <div class="hytd_bottom1right"><img src="../.././assets/hytd3.png" alt=""></div>
          </div>
          <div class="hytd_bottom4">
            <div class="hytd_bottom1right"><img src="../.././assets/hytd4.png" alt=""></div>
            <div class="hytd_bottom1left">合同相对方的及时监控的有 效手段，无法及时发现合同 风险；经办部门在发现潜在 风险时，缺乏沟通渠道。</div>
          </div>
          <div class="hytd_bottom5">
            <div class="hytd_bottom1left">合同管理并未完全实现电子化，合 同评价、合同纠纷及合同归档暂无 相关系统支持，电子化及线上化程 度有待进一步的提高和加强。</div>

            <div class="hytd_bottom1right"><img src="../.././assets/hytd5.png" alt=""></div>
          </div>
          <div class="hytd_bottom6">
            <div class="hytd_bottom1right"><img src="../.././assets/hytd6.png" alt=""></div>
            <div class="hytd_bottom1left">由于信息分散且并无关联，加上 内部人员的离职或异动，一旦合 同发生纠纷，取证相当困难，容 易在纠纷中处于被动地位。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 九大业务模块 -->
    <div class="ywmk">
      <div class="ywmkcontent">
         <div class="ywmkcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">九大业务模块</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="ywmo_bottom">
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon1.png" alt=""></div>
            <div class="ywmo_bottomevery1center">合同管理</div>
            <div class="ywmo_bottomevery1bottom">进行合同全生命、全周期管理</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon2.png" alt=""></div>
            <div class="ywmo_bottomevery1center">法律风险</div>
            <div class="ywmo_bottomevery1bottom">管理法律风险库及识别法律风险</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon3.png" alt=""></div>
            <div class="ywmo_bottomevery1center">纠纷案件处理</div>
            <div class="ywmo_bottomevery1bottom">对案件过程进行管理</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon4.png" alt=""></div>
            <div class="ywmo_bottomevery1center">知识产权管理</div>
            <div class="ywmo_bottomevery1bottom">实现知识产权全生命周期管理。</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon5.png" alt=""></div>
            <div class="ywmo_bottomevery1center">综合事务</div>
            <div class="ywmo_bottomevery1bottom">了解和管理各级单位法律事务</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon6.png" alt=""></div>
            <div class="ywmo_bottomevery1center">合规管理</div>
            <div class="ywmo_bottomevery1bottom">确保行为合规，保护经理人</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon7.png" alt=""></div>
            <div class="ywmo_bottomevery1center">合同管理</div>
            <div class="ywmo_bottomevery1bottom">进行合同全生命、全周期管理</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon8.png" alt=""></div>
            <div class="ywmo_bottomevery1center">律师律所</div>
            <div class="ywmo_bottomevery1bottom">对律师律所进行管理</div>
          </div>
          <div class="ywmo_bottomevery">
            <div class="ywmo_bottomevery1top"><img src="../.././assets/ywmkicon9.png" alt=""></div>
            <div class="ywmo_bottomevery1center">人员管理</div>
            <div class="ywmo_bottomevery1bottom">对法律条线人员信息进行管理</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 四大系统优势 -->
    <div class="xtys">
      <div class="xtyscontent">
          <div class="xtyscontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">四大系统优势</div>
          <div class="hxystitleback2"></div>
          </div>
          <div class="xty_bottom">
            <div class="xty_bottom1">
              <div class="xty_bottom1left">
                <div class="xty_bottom1lefttop">多元化</div>
                <div class="xty_bottom1leftbottom">覆盖各个行业，融入不同行业法务管理特点</div>
              </div>
              <div class="xty_bottom1right"><img src="../.././assets/xtys1.png" alt=""></div>
            </div>
            <div class="xty_bottom2">
              <div class="xty_bottom1right"><img src="../.././assets/xtys2.png" alt=""></div>
              <div class="xty_bottom1left">
                <div class="xty_bottom1lefttop">集团化</div>
                <div class="xty_bottom1leftbottom">充分发挥流程和权限管控能力，快速实现集团化实施</div>
              </div>
            </div>
            <div class="xty_bottom3">
              <div class="xty_bottom1left">
                <div class="xty_bottom1lefttop">全面化</div>
                <div class="xty_bottom1leftbottom">提供企业法律管理总体解决方案、覆盖集团企业法务管理的方方面面</div>
              </div>
              <div class="xty_bottom1right"><img src="../.././assets/xtys3.png" alt=""></div>
            </div>
            <div class="xty_bottom4">
              <div class="xty_bottom1right"><img src="../.././assets/xtys4.png" alt=""></div>
              <div class="xty_bottom1left">
                <div class="xty_bottom1lefttop">个性化</div>
                <div class="xty_bottom1leftbottom">灵活可扩展的平台架构支持响应企业个性化需求</div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <!-- 合同管理服务框架 -->
    <div class="fwkj">
      <div class="fwkjcontent">
        <div class="fwkjcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">合同管理服务框架</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="fwkj_bottom"></div>
      </div>
    </div>
    <!-- 咨询热线 -->
    <!-- <zi-xun></zi-xun> -->
    <!-- 版权 -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import ZiXun from '../../components/ZiXun.vue'
  export default {
     metaInfo: {
      title: '中科星图瑞云科技有限公司', // set a title
      meta: [{                 // set meta
        name: '智慧法务',
        content: '中科星图瑞云科技有限公司'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: ''
      }]
    },
  components: { TabarBottom, ZiXun },
    data(){
      return{

      }
    },
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
// 咨询热线
.zxrx{
  // width: 1903px;
  height: 120px;
  background: #FFA000;
  margin: 0 auto;
  .zxrxtitle{
    float: left;
    margin-left: 771px;
    margin-top: 52px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  .zxrxphone{
    float: left;
    margin-left: 20px;
    margin-top: 52px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
}
// 合同管理服务框架
.fwkj{
  // width: 1903px;
  height: 886px;
  margin: 0 auto;
  background: #F8F8F8;
  .fwkjcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
       .fwkjcontenttitle{
  width: 550px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom:68px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.fwkj_bottom{
  width: 100%;
  height: 604px;
  background: url('../.././assets/fwkjback.png') no-repeat center;
  background-size: 1200px 604px;
}
  }

}
// 四大系统优势
.xty_bottom1{
  width: 599px;
  height: 139px;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  .xty_bottom1left{
    margin-left: 40px;
    width: 340px;
    height: 72px;

    text-align: right;
    .xty_bottom1lefttop{
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
      margin-bottom: 24px;
    }
    .xty_bottom1leftbottom{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  .xty_bottom1right{
    margin-left: 40px;
  }
}
.xty_bottom2{
  width: 600px;
  height: 139px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  .xty_bottom1left{
    margin-left: 40px;
    width: 340px;
    height: 72px;
    text-align: left;
    .xty_bottom1lefttop{
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
      margin-bottom: 24px;
    }
    .xty_bottom1leftbottom{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  .xty_bottom1right{
    margin-left: 40px;
  }
}
.xty_bottom3{
  width: 599px;
  height: 140px;
  display: flex;
  align-items: center;
  .xty_bottom1left{
    margin-left: 40px;
    width: 340px;
    height: 72px;
    text-align: right;
    .xty_bottom1lefttop{
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
      margin-bottom: 24px;
    }
    .xty_bottom1leftbottom{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  .xty_bottom1right{
    margin-left: 40px;
  }
}
.xty_bottom4{
  width: 599px;
  height: 140px;
  display: flex;
  align-items: center;

  border-left: 1px solid #D9D9D9;
  .xty_bottom1left{
    margin-left: 40px;
    width: 340px;
    height: 72px;
    text-align: left;
    .xty_bottom1lefttop{
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
      margin-bottom: 24px;
    }
    .xty_bottom1leftbottom{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  .xty_bottom1right{
    margin-left: 40px;
  }
}
.xtys{
  // width: 1903px;
  height: 572px;
  margin: 0 auto;
  .xtyscontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    .xtyscontenttitle{
  width: 500px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.xty_bottom{
  width: 1200px;
  height: 280px;

  display: flex;
  flex-wrap: wrap;
}
  }
}
// 九大业务模块
.ywmo_bottomevery1top{
    margin-top: 34px;
    margin-bottom: 30px;
}
.ywmo_bottomevery1center{
  font-size: 24px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #282A2E;
  margin-bottom: 20px;
}
.ywmo_bottomevery1bottom{
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #848079;
  }
  .ywmk{
    // width: 1903px;
    height: 1074px;
    margin: 0 auto;
    background: url('../.././assets/ywmkback.png') no-repeat center center;
    background-size: 1989px 1074px ;
    .ywmkcontent{
      width: 1200px;
      height: inherit;
      margin: 0 auto;
         .ywmkcontenttitle{
  width: 494px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 85px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.ywmo_bottom{
  width: 1200px;
  height: 780px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  .ywmo_bottomevery{
    width: 380px;
    height: 240px;
      text-align: center;
      background: #fff;
  }
}
    }
  }
// 行业痛点
  .hytd{
    // width: 1903px;
    height: 810px;
    margin: 0 auto;
    .hytdcontent{
      // width: 1903px;

      height: 810px;
      //
      margin: 0 auto;
      .hytdcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.hytd_bottom{
  // width: 100%;
  width: 1200px;
  height: 545px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  .hytd_bottom1{
    height: 180px;
    width: 485px;
    // float: left;
    margin-left: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
   .hytd_bottom2{
    height: 180px;
    width: 485px;
    // float: left;
    margin-left: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
  .hytd_bottom3{
    height: 180px;
    width: 485px;
    // float: left;
    // margin-left: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
  .hytd_bottom4{
    height: 180px;
    width: 485px;
    // float: left;
    margin-left: 217px;
  display: flex;
  align-items: center;
  justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
  .hytd_bottom5{
    height: 180px;
    width: 485px;
    // float: left;
    margin-left: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
  .hytd_bottom6{
    height: 180px;
    width: 485px;
    // float: left;
    margin-left: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;

    .hytd_bottom1left{
      width: 310px;
      height: 70px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
    .hytd_bottom1right{
      width: 160px;
      height: 180px;
    }
  }
}
    }
  }
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  left: 16px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/zhfwindexback1.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
 .indexlogo{
  // float: left;
  width: 220px;
  height: 52px;
  // margin-left: 360px;
  // margin-top: 32px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
  // background: url('../.././assets/zhfabutton.png') no-repeat center center;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;

  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
}
</style>
